import React from 'react'
import Logo from '../assets/images/logo.svg'
import LicenseIcon from '../assets/images/license.svg'
import MarkerIcon from '../assets/images/marker.svg'
import PhoneIcon from '../assets/images/phone.svg'
import ClockIcon from '../assets/images/clock.svg'
import FacebookIcon from '../assets/images/facebook.svg'
import InstagramIcon from '../assets/images/instagram.svg'
import YoutubeIcon from '../assets/images/youtube.svg'
import LinkedinIcon from '../assets/images/linkedin.svg'
import ComplaintsBookIcon from '../assets/images/livro.svg'
import InvisualLogo from '../assets/images/invisual.svg'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import { handlePhoneClick } from '../utils/handleClick'

export const Footer = () => {

  return (
    <StaticQuery
      query={graphql`
        query {
          miscJson {
            alternAddress
            phone1
            phone2
            email
            alternSchedule
            facebookUrl
            instagramUrl
            youtubeUrl
            linkedinUrl
            complaintsBookUrl
            socialsString
            createdBy
          }
        }
      `}
      render={({ miscJson: content }) => (
        <StyledFooter>
          <div className="logo">
            <Logo />
          </div>

          <div className='licenses'>
            <div className="holder">
            <LicenseIcon />
              <p>Licença ERS: 20817/2021 <br/><br/> Registo ERS: E155325 <br/><br/> NIF: 513 795 464</p>
            </div>
          </div>

          <div className="address">
            <div className="holder">
              <PhoneIcon />
              <p>
                <a onClick={() => handlePhoneClick(`tel: ${content.phone1.replace(/\s/g, '')}`, 'Móvel')}
                >
                  {content.phone1}
                </a>
              </p>
              <p>
                <small>«Chamada para a rede móvel nacional»</small>{' '}
              </p>
              <br />
              <p>
                <a onClick={() => handlePhoneClick(`tel: ${content.phone2.replace(/\s/g, '')}`, 'Fixo')}
                >
                  {content.phone2}
                </a>
              </p>
              <p>
                <small>«Chamada para a rede fixa nacional»</small>
              </p>
              <br />
              <p>
                <a href={`mailto:${content.email}`}>{content.email}</a>
              </p>
            </div>
          </div>

          <div className="social">
            <div className="holder">
              <MarkerIcon />
              <p dangerouslySetInnerHTML={{ __html: content.alternAddress }} />
            </div>

            <div className="flex-center social-icons">
              <a href={content.facebookUrl} target="_blank" rel="noopener">
                <FacebookIcon />
              </a>
              <a href={content.instagramUrl} target="_blank" rel="noopener">
                <InstagramIcon />
              </a>
              <a href={content.youtubeUrl} target="_blank" rel="noopener">
                <YoutubeIcon />
              </a>
              <a href={content.linkedinUrl} target="_blank" rel="noopener">
                <LinkedinIcon />
              </a>
            </div>

            <p dangerouslySetInnerHTML={{ __html: content.socialsString }}></p>
          </div>

          <div className="schedule">
            <ClockIcon />
            <p dangerouslySetInnerHTML={{ __html: content.alternSchedule }} />
          </div>

          <div className="complaints">
            <a href={content.complaintsBookUrl} target="_blank" rel="noopener">
              <ComplaintsBookIcon className="complaintsbook" />
            </a>

            <div className="createdBy flex-center">
              <span className="white">{content.createdBy}</span>
              <InvisualLogo />
            </div>
          </div>
        </StyledFooter>
      )}
    />
  )
}

const StyledFooter = styled.footer`
  padding: ${({ theme }) => `${theme.spacingL} ${theme.spacingM}`};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  place-items: flex-start center;
  background: ${({ theme }) => theme.blue};

  p {
    font-size: 1.5rem;
    line-height: 2.5rem;
    letter-spacing: 0.03rem;
    font-weight: ${({ theme }) => theme.fontBold};
    color: ${({ theme }) => theme.white};
    text-align: center;
  }

  a{
    cursor: pointer;
  }

  .holder,
  .schedule {
    text-align: center;

    svg {
      width: 2.2rem;
    }
  }

  .holder:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacingXS};
  }

  .social {
    .flex-center {
      justify-content: center;
    }

    svg {
      margin: 0 ${({ theme }) => theme.spacingXXXS};
      width: 1.8rem;

      path,
      rect {
        fill: ${({ theme }) => theme.white};
      }
    }

    p:last-child {
      margin-top: ${({ theme }) => theme.spacingXXXS};
    }
  }

  .logo {
    align-self: center;

    svg {
      max-width: 85%;
    }
  }

  .createdBy {
    justify-content: flex-end;
    margin-top: ${({ theme }) => theme.spacingS};

    span {
      font-weight: ${({ theme }) => theme.fontThin};
      font-size: 1.5rem;
      text-transform: uppercase;
    }

    svg {
      width: 3.5rem;
      margin-left: ${({ theme }) => theme.spacingXS};
    }
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-gap: ${({ theme }) => theme.spacingXS};

    .createdBy {
      justify-content: center;
    }

    .logo {
      text-align: center;
    }

    .social-icons {
      margin-top: ${({ theme }) => theme.spacingM};
    }

    .complaints {
      text-align: center;

      svg.complaintsbook {
        width: 21rem;
      }
    }
  }
`
